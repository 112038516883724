import axios from "@/lib/axios";
import notification from "@/utils/notifications";
import registationActions from "./registration/actions";

/**
 * This API"s are per location
 * @param {String} locationId
 */

const fetchOrgLocationDetails = async ({ commit }, { orgId, locationId, setOrgDetails = true }) => {
  try {
    const response = await axios.get(`accounts/v1/org/${orgId}/location/${locationId}`);
    if (response) {
      const { data } = response;
      if (setOrgDetails) {
        commit("setOrgLocationDetails", data);
      }
      return data;
    }
  } catch (error) {
    notification("warning", error.response.data.message);
  }
};

const fetchLocationDetails = async (_, { locationId }) => {
  try {
    const response = await axios.get(`accounts/v1/location/${locationId}`);
    if (response) {
      const { data } = response;
      return data;
    }
  } catch (error) {
    notification("warning", error.response.data.message);
  }
};

/**
 * DYNAMIC API FOR PATIENTS LIST WITH SEARCHING
 * @param {String} type
 * @param {String} locationId
 * @param {String} search
 * @param {Boolean} isLookups
 * @returns
 */
// DEPRECATED
const fetchPatients = async ({ commit }, { type, locationId, searchQuery = "", isLookups = false }) => {
  try {
    const patientTypeQuery = type ? `&pType=${type}` : "";
    const searchPatientQuery = `search?q=${searchQuery}`;

    const response = await axios.get(`/patient/v1/location/${locationId}/patient/${searchPatientQuery}${patientTypeQuery}`);
    if (response) {
      const { data } = response;
      switch (type) {
        case "medcenter":
          if (isLookups) {
            return data;
          } else {
            commit("setLocationMedcenterPatients", data);
          }
          break;
        case "intellipel":
          if (isLookups) {
            return data;
          } else {
            commit("setLocationIntellipelPatients", data);
          }
          break;
        default:
          if (isLookups) {
            return data;
          } else {
            commit("setLocationAllPatients", data);
          }
          break;
      }
    }
  } catch (error) {
    notification("warning", error.response.data.message);
  }
};

const updateLocationStatus = async (_, payload) => {
  const { isActivate } = payload;
  const { locationId } = payload;
  let response = "";
  if (isActivate) {
    response = await axios.put(`accounts/v1/location/${locationId}/activate`);
  } else {
    response = await axios.put(`accounts/v1/location/${locationId}/deactivate`);
  }
  return response.data;
};

export default {
  fetchOrgLocationDetails,
  /**
   * LOCATION DETAILS API
   */
  fetchLocationDetails,
  updateLocationStatus,
  /**
   * PATIENTS LIST API
   */
  fetchPatients,
  ...registationActions,
};
