export default {
  state: {
    loggedIn: false,
    simpatraAdmin: false,
    locationPermissions: [],
    locations: [],
    id: "",
    type: "",
    firstName: "",
    lastName: "",
    email: "",
    admin: false,
    userType: "",
    accessRights: [],
  },

  getters: {
    getCurrentUser: state => {
      return state;
    },
    getUserId: state => {
      return state.id;
    },
    getUserFirstName: state => {
      return `${state.firstName}`;
    },
    getUserLastName: state => {
      return `${state.lastName}`;
    },
    getUserLoggedInStatus: state => {
      return state.loggedIn;
    },
    getLocationProfilePermission: state => {
      const permissions = state.locationPermissions;
      if (permissions.length > 0) {
        const perm = permissions.find(permission => {
          return permission.name === "LocProfile";
        });
        if (perm) {
          return perm.value;
        }
      }
      return 0;
    },
    getLocationPaymentPermission: state => {
      const permissions = state.locationPermissions;
      if (permissions.length > 0) {
        const perm = permissions.find(permission => {
          return permission.name === "LocPayment";
        });
        if (perm) {
          return perm.value;
        }
      }
      return 0;
    },
    getLocationLicensePermission: state => {
      const permissions = state.locationPermissions;
      if (permissions.length > 0) {
        const perm = permissions.find(permission => {
          return permission.name === "LocLicenses";
        });
        if (perm) {
          return perm.value;
        }
      }
      return 0;
    },
    getLocationRegistrationPermission: state => {
      const permissions = state.locationPermissions;
      if (permissions.length > 0) {
        const perm = permissions.find(permission => {
          return permission.name === "LocRegistration";
        });
        if (perm) {
          return perm.value;
        }
      }
      return 0;
    },
    isLoggedIn() {
      if (localStorage.getItem("exp") && localStorage.getItem("access_token")) {
        const tokenExpiration = Number(localStorage.getItem("exp"));

        const isExpired = tokenExpiration < new Date() / 1000;

        return !isExpired;
      }

      return false;
    },
  },

  mutations: {
    setUserInfo: (state, payload) => {
      if (payload.sim.simpatraAdmin) {
        state.simpatraAdmin = payload.sim.simpatraAdmin;
      }
      state.loggedIn = true;
      state.id = payload.sim.user.subject;
      state.type = payload.sim.user.userType;
      state.firstName = payload.sim.user.firstName;
      state.lastName = payload.sim.user.lastName;
      state.email = payload.sim.user.email;
      state.locations = payload.sim.details ? payload.sim.details.locations : [];
    },
    setOrgLocations: (state, payload) => {
      state.locations = payload.locations;
    },
    setAdminStatus(state, payload) {
      state.admin = payload;
    },
    setCurrentUserType(state, payload) {
      state.userType = payload.userType;
    },
    setCurrentUserTypes(state, payload) {
      state.type = payload;
    },
    setAccessRights(state, payload) {
      state.accessRights = payload;
    },
  },

  actions: {
    setUserInfo: ({ commit }, payload) => {
      commit("setUserInfo", payload);
    },
    setAdminStatus({ commit }, payload) {
      commit("setAdminStatus", payload);
    },
    setCurrentUserTypes({ commit }, payload) {
      commit("setCurrentUserTypes", payload);
    },
    commitCurrentUserType({ commit }, payload) {
      commit("setCurrentUserType", payload);
    },
    clearLocalStore: async () => {
      localStorage.removeItem("exp");
      localStorage.removeItem("access_token");
      localStorage.removeItem("finalizeOrder");
      localStorage.removeItem("epcsIDProofing");
      localStorage.removeItem("primaryState");
    },
    setUserAccessRights({ commit }, payload) {
      commit("setAccessRights", payload);
    },
  },
};
