import { get, merge } from "lodash";
import PostSimpatraServiceProxy from "@/proxies/back-end/simpatra-service/post";
import GetSimpatraServiceProxy from "@/proxies/back-end/simpatra-service/get";
import DeleteSimpatraServiceProxy from "@/proxies/back-end/simpatra-service/delete";
import PutSimpatraServiceProxy from "@/proxies/back-end/simpatra-service/put";

// Helper function to extract pagination details
const extractPaginationDetails = (responseData, queryParams) => ({
  pageNumber: queryParams.pageNumber ? queryParams.pageNumber : 0,
  size: get(responseData, "pageSize", 5),
  totalPages: get(responseData, "totalPages", 0),
  totalData: get(responseData, "totalData", 0),
});

const commitData = (commit, setStore, responseData, paginationDetails) => {
  const data = Array.isArray(responseData) ? responseData : get(responseData, "content", []);
  commit(setStore, merge({ data }, paginationDetails));
  return data;
};

const manageLoadingState = async (commit, action, callback) => {
  commit("setCartState", { type: "isLoading", value: true });
  const result = await callback();
  commit("setCartState", { type: "isLoading", value: false });
  return result;
};

const executePostQuery = async (query, payload, notificationParams) => {
  const proxy = new PostSimpatraServiceProxy();
  const response = await proxy.execute(query, payload, notificationParams);
  return response ? response.data : null;
};

const executePutQuery = async (query, payload, notificationParams) => {
  const proxy = new PutSimpatraServiceProxy();
  const response = await proxy.execute(query, payload, notificationParams);
  return response ? response.data : null;
};
const executeDeleteQuery = async (query, notificationParams) => {
  const proxy = new DeleteSimpatraServiceProxy();
  const response = await proxy.execute(query, { notificationParams });
  return response ? response.data : null;
};

export default {};
