import { get } from "lodash";
import GetSimpatraServiceProxy from "@/proxies/back-end/simpatra-service/get";
import PostSimpatraServiceProxy from "@/proxies/back-end/simpatra-service/post";
import PutSimpatraServiceProxy from "@/proxies/back-end/simpatra-service/put";

const fetchPatientDetailsById = async ({ commit }, { patientId }) => {
  const proxy = new GetSimpatraServiceProxy();
  const query = `jpatient/v1/patient/${patientId}`;
  const response = await proxy.execute(query);
  if (response) {
    const { data } = response;
    commit("setPatientDetails", data);
    return data;
  }
};

const fetchIntelipelAppointments = async ({ commit }, locationId) => {
  const proxy = new GetSimpatraServiceProxy();
  const query = `patient/v1/location/${locationId}/appointment`;
  const response = await proxy.execute(query);
  if (response) {
    const { data } = response;
    commit("setAppointments", data);
    return data;
  }
};

// Update
const updateAppointment = async ({ commit }, payload) => {
  const proxy = new PutSimpatraServiceProxy();
  const { locationId, aptId } = payload;
  const body = {
    patientId: payload.patientId,
    doctorId: payload.doctorId,
    type: payload.type,
    date: payload.date,
    status: payload.status,
    notes: get(payload, "notes", ""),
  };
  const query = `patient/v1/location/${locationId}/appointment/${aptId}`;
  const response = await proxy.execute(query, body, {
    displaySuccessMessage: true,
    successNotificationMsg: "Successfully updated appointment!",
  });

  if (response) {
    const { data } = response;
    commit("updateAppointmentList", {
      updateProp: true,
      id: aptId,
      prop: "status",
      value: payload.status,
    });
    return data;
  }
};

export default {
  fetchPatientDetailsById,
  fetchIntelipelAppointments,
  updateAppointment,
};
