const setBulkOrderVisibility = (state, payload) => {
  state.displayBulkOrders = payload || false;
};
const setMarketplaceVendorLookup = (state, payload) => {
  state.vendorLookup = payload || [];
};
export default {
  setBulkOrderVisibility,
  setMarketplaceVendorLookup,
};
