import currentLocation from "@/store/currentLocation";
import currentPatient from "@/store/currentPatient";
import navigation from "@/store/navigation";
import org from "@/store/org";
import subscriptionPlan from "@/store/subscriptionPlan";
import subscriptionFeature from "@/store/subscriptionFeature";
import vendorProduct from "@/store/vendor/product";
import vendorProducts from "@/store/vendor/products";
import user from "@/store/user";
import vendor from "@/store/vendor";
import directoryProfiles from "@/store/directoryProfiles";
import admin from "@/store/simpatraAdmin";

import marketplace from "@/store/marketplace";
// new marketplace modules
import marketplaceSearch from "@/store/marketplace/search";
import marketplaceProduct from "@/store/marketplace/product";
import marketplaceVendor from "@/store/marketplace/vendor";
import marketplaceCategory from "@/store/marketplace/category";

// new org modules
import orgReceipts from "@/store/org/receipts";
import orgSubscription from "@/store/org/subscription";
import orgLeads from "@/store/org/leads";

// new location modules
import locationReceipts from "@/store/location/receipts";
import locationRegistrations from "@/store/location/registrations";

import step from "@/store/onboarding/step";
import sellerStore from "@/store/sellerStore";
import landingPage from "@/store/landingPage";
import medCenter from "@/store/medCenter";

import labOrders from "@/store/med-center/labOrders";
import globalStore from "@/store/globalStore";

// Vendor
import vendorAccountsModule from "@/store/vendorModule/vendorAccountsModule";
import vendorOrdersModule from "@/store/vendorModule/vendorOrdersModule";
import vendorProductsModule from "@/store/vendorModule/vendorProductsModule";
import vendorTierModule from "@/store/vendorModule/vendorTierModule";

import usersModule from "@/store/usersModule";
import authModule from "@/store/authModule";

import ordersModule from "@/store/ordersModule";
import locationsModule from "@/store/locationsModule";

import orgModule from "@/store/orgModule";
import filesModule from "@/store/filesModule";

// Marketplace
import orderHistoryModule from "@/store/marketplaceModule/orderHistoryModule";
import marketplaceModule from "@/store/marketplaceModule";
import creditCardModule from "@/store/creditCardModule";

// Intellipel
import consultsModule from "@/store/consultsModule";

import accountsModule from "@/store/accountsModule";
import patientModule from "@/store/patientModule";
import jPatientModule from "@/store/jpatientModule";
import productsModule from "@/store/productsModule";
import subscriptionModule from "@/store/subscriptionModule";
import paymentModule from "@/store/paymentModule";
import directoryModule from "@/store/directoryModule";
import shippingModule from "@/store/shippingModule";
import lookupModule from "@/store/lookupModule";
import onboardingModule from "@/store/onboardingModule";
import publicAPIModule from "@/store/publicAPIModule";
import initializeUserModule from "@/store/initializeUserModule";
import rolesAndPermissionsModule from "@/store/rolesAndPermissionsModule";
import cartModule from "@/store/cartModule";

export default {
  marketplace: {
    marketplace,
    marketplaceSearch,
    marketplaceProduct,
    marketplaceVendor,
    marketplaceCategory,
    orderHistoryModule,
    marketplaceModule,
    ordersModule,
  },
  vendor: {
    vendor,
    vendorProduct,
    vendorProducts,
    vendorAccountsModule,
    vendorOrdersModule,
    vendorProductsModule,
    vendorTierModule,
  },
  organization: {
    org,
    orgReceipts,
    orgSubscription,
    orgLeads,
    orgModule,
    creditCardModule,
  },
  location: {
    locationReceipts,
    locationRegistrations,
    locationsModule,
  },
  user: {
    user,
    usersModule,
    authModule,
  },
  admin: {
    admin,
  },
  medicalCenter: {
    medCenter,
    labOrders,
  },
  other: {
    currentLocation,
    currentPatient,
    navigation,
    subscriptionPlan,
    subscriptionFeature,
    directoryProfiles,
    step,
    sellerStore,
    landingPage,
    globalStore,
    filesModule,
    consultsModule,
    accountsModule,
    patientModule,
    jPatientModule,
    productsModule,
    subscriptionModule,
    paymentModule,
    directoryModule,
    shippingModule,
    lookupModule,
    onboardingModule,
    publicAPIModule,
    initializeUserModule,
    rolesAndPermissionsModule,
    cartModule,
  },
};
