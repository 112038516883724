import GetSimpatraServiceProxy from "@/proxies/back-end/simpatra-service/get";

const fetchPatientFulfillments = async (_, { patientId, pageNumber = 0, pageSize = 10, paginate = false }) => {
  const proxy = new GetSimpatraServiceProxy();
  const queryParams = {};
  if (paginate) {
    queryParams.pageNumber = pageNumber;
    queryParams.pageSize = pageSize;
  }
  const query = `orders/v1/fulfillment/patient/${patientId}/prescription`;
  const response = await proxy.execute(query, queryParams);
  if (response) {
    const { data } = response;
    return data;
  }
};

const fetchPrescriptionHistory = async (_, payload = {}) => {
  const proxy = new GetSimpatraServiceProxy();
  const queryParams = {};
  Object.keys(payload).forEach(key => {
    if (payload[key] !== undefined) {
      queryParams[key] = payload[key];
    }
  });
  const query = "orders/v1/fulfillment/order-history";
  const response = await proxy.execute(query, queryParams);
  if (response) {
    const { data } = response;
    return data;
  }
};

export default {
  fetchPatientFulfillments,
  fetchPrescriptionHistory,
};
